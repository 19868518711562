import React from "react"
import { graphql } from "gatsby"


class Schedule extends React.Component {
    
    constructor(props){
        super(props);
    }  
  
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
        <div>
        </div>
    )
  }

  componentDidMount() {
      const script = document.createElement("script");

      var city_one = "";
      var city_two = "";
      var airline_add = "";
      const query = this.props.location.search;
      if(query.length > 9){
        city_one=query.substring(query.length-8,query.length-5)
        city_two=query.substring(query.length-5,query.length-2)
        airline_add="airline="+query.substring(query.length-2, query.length)+"&";
      } else if(query.length > 8){
        city_one=query.substring(query.length-6,query.length-3);
        city_two=query.substring(query.length-3,query.length);
      }

      script.src = "//tp.media/content?promo_id=2811&shmarker=219764&campaign_id=100&locale=en&target_host=search.jetradar.com&origin="+city_one+"&destination="+city_two+"&border_radius=0&"+airline_add+"color_background=fdfffc&powered_by=false";
      script.charset = "utf-8";
      script.async = true;

      document.body.appendChild(script);
  }

}

export default Schedule

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`